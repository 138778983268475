<template>
    <div class="home d-flex">
        <v-container fluid class="d-flex flex-grow-1">
            <v-row dense class="d-flex flex-grow-1">
                <v-col lg="12" md="12" xs="12" cols="12">
                    <v-card
                        outlined
                        class="d-flex flex-column"
                        style="height: 100%"
                    >
                        <v-card-title
                            style="border-bottom: 1px solid #404040"
                            class="pt-2 pb-2"
                        >
                            <div class="text-left v-card-custom-title-left">
                                Symbol Mapping
                            </div>
                            <div class="text-right v-card-custom-title-right">
                                <v-menu offset-y dense>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            small
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ selectedLp }}
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item
                                            v-for="(item, index) in lpList"
                                            :key="item.id"
                                            v-on:click="changeLp(item)"
                                        >
                                            <v-list-item-title>{{
                                                item.lp_name.toUpperCase()
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <v-btn
                                    small
                                    color="default"
                                    :loading="loading"
                                    @click="refreshMapping"
                                    ><v-icon>mdi-refresh</v-icon></v-btn
                                >
                            </div>
                        </v-card-title>

                        <v-data-table
                            class="flex-grow-1"
                            dense
                            :headers="symbolMapHeader"
                            :items="filteredData"
                            :loading="loading"
                            loading-text="Loading... Please wait"
                            :items-per-page="-1"
                            :height="tableHeight"
                            :hide-default-footer="true"
                            fixed-header
                            :header-props="{ sortIcon: null }"
                        >
                            <template v-slot:header.api_code="{ header }">
                                {{ header.text }}
                                <v-menu
                                    offset-y
                                    :close-on-content-click="false"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click.prevent="
                                                onSearch('apiCodeSearch')
                                            "
                                        >
                                            mdi-magnify
                                        </v-icon>
                                    </template>
                                    <div
                                        style="
                                            background-color: #1e1e1e;
                                            width: 280px;
                                        "
                                    >
                                        <v-text-field
                                            v-model="apiSearch"
                                            class="px-4"
                                            type="text"
                                            ref="apiCodeSearch"
                                            label="Enter the search term"
                                            style="width: 100%"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                        <v-btn
                                            @click="apiSearch = ''"
                                            small
                                            text
                                            color="primary"
                                            class="ml-2 mb-2"
                                            style="padding: 6px"
                                        >
                                            Clean
                                        </v-btn>
                                    </div>
                                </v-menu>
                            </template>
                            <template v-slot:header.pk="{ header }">
                                {{ header.text }}
                                <v-menu
                                    offset-y
                                    :close-on-content-click="false"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click.prevent="
                                                onSearch('pkSearch')
                                            "
                                        >
                                            mdi-magnify
                                        </v-icon>
                                    </template>
                                    <div
                                        style="
                                            background-color: #1e1e1e;
                                            width: 280px;
                                        "
                                    >
                                        <v-text-field
                                            v-model="pkSearch"
                                            class="px-4"
                                            type="text"
                                            ref="pkSearch"
                                            label="Enter the search term"
                                            style="width: 100%"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                        <v-btn
                                            @click="pkSearch = ''"
                                            small
                                            text
                                            color="primary"
                                            class="ml-2 mb-2"
                                            style="padding: 6px"
                                        >
                                            Clean
                                        </v-btn>
                                    </div>
                                </v-menu>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon dense v-on:click="toggleDialog(item)"
                                    >mdi-pen</v-icon
                                >
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <v-icon
                                    dense
                                    @click="
                                        dialogRemove.visible = true;
                                        removeData = [item];
                                    "
                                    >mdi-delete</v-icon
                                >
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
            v-model="dialogMapping.visible"
            :fullscreen="dialogMapping.fullscreen"
            width="1200"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialogMapping.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="
                            dialogMapping.fullscreen = !dialogMapping.fullscreen
                        "
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn
                        color="white"
                        icon
                        @click="dialogMapping.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-form>
                    <v-container>
                        <v-row
                            v-for="data in Object.keys(
                                dialogMapping.symbolMapInfo
                            )"
                            :key="data"
                        >
                            <v-col cols="3">
                                <label
                                    style="
                                        display: flex;
                                        align-items: center;
                                        height: 100%;
                                    "
                                >
                                {{ keyTitleRef[data] }}
                                </label>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field
                                    v-model="dialogMapping.symbolMapInfo[data]"
                                    :readonly="
                                        data == 'id' ||
                                        data == 'Symbol' ||
                                        data == 'Server Name'
                                    "
                                    required
                                    :placeholder="
                                        'Please enter a new value for this field.'
                                    "
                                ></v-text-field
                            ></v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogMapping.visible = false"
                        :disabled="loading"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="green darken-1"
                        text
                        @click="updateMapping()"
                        :loading="loading"
                        ><span>Update</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <batch-add-symbol-map-dialog
            ref="batchDialog"
            v-bind="$attrs"
            :selected-lp-id="selectedLpId"
        />
        <v-dialog
            v-model="dialogRemove.visible"
            :fullscreen="dialogRemove.fullscreen"
            width="1300"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialogRemove.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="
                            dialogRemove.fullscreen = !dialogRemove.fullscreen
                        "
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn
                        color="white"
                        icon
                        @click="dialogRemove.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                        ></v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-data-table
                                dense
                                :headers="
                                    symbolMapHeader.filter(
                                        (item) => item.value !== 'action'
                                    )
                                "
                                :items="removeData"
                                item-key="id"
                                class="elevation-0"
                                :loading="loading"
                                :items-per-page="-1"
                                id="home-table"
                                :hide-default-footer="true"
                                fixed-header
                                :header-props="{ sortIcon: null }"
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogRemove.visible = false"
                        :disabled="loading"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="red darken-1"
                        text
                        @click="confirmDeletion()"
                        :loading="loading"
                        ><span>Confirm</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import BatchAddSymbolMapDialog from "./BatchAddSymbolMapDialog";

export default {
    mixins: [permission, helper, snackbar],
    components: { BatchAddSymbolMapDialog },
    props: {
        tableHeight: Number,
    },
    data() {
        return {
            apiSearch: "",
            pkSearch: "",
            selectedLpId: -1,
            selectedLp: "ALL",
            search: "",
            dialogMapping: {
                visible: false,
                symbolMapInfo: {},
                fullscreen: false,
                title: null,
            },
            dialogRemove: {
                title: "Are you sure you want to remove this record?",
                fullscreen: false,
                visible: false,
            },
            removeData: null,
            keyTitleRef:{
                id: "ID",
                api_code: "LP (API) Name",
                symbol: "Symbol",
                pk: "PK Name",
                ratio: "Multiplier"
            }
        };
    },
    computed: {
        ...mapState("LP", [
            "symbolMapData",
            "symbolMapHeader",
            "loading",
            "lpList",
            "updateStatus",
            "batchAddLPId",
        ]),
        lpSpecifiedSymbolMapData() {
            return this.selectedLpId == -1
                ? this.symbolMapData
                : this.symbolMapData.filter(
                      (item) => item.lp_id === this.selectedLpId
                  );
        },
        filteredData() {
            return this.lpSpecifiedSymbolMapData.filter(
                (item) =>
                    this.activeLP.includes(item.lp_name.toUpperCase()) &&
                    item.api_code
                        .toUpperCase()
                        .match(this.apiSearch.toUpperCase()) &&
                    item.pk.toUpperCase().match(this.pkSearch.toUpperCase())
            );
        },
        activeLP() {
            let result = [];
            this.lpList.map((item) => {
                if (item.active === 1) {
                    result.push(item.lp_name.toUpperCase());
                }
            });
            return result;
        },
    },
    watch: {
        lpList: {
            immediate: true,
            handler(nv) {
                if (nv.length) {
                    this.selectedLpId =
                        this.batchAddLPId != -1 ? this.batchAddLPId : nv[0].id;
                    this.selectedLp = nv.find(
                        (item) => item.id === this.selectedLpId
                    ).lp_name;
                }
            },
        },
        selectedLpId: {
            immediate: true,
            handler(lp_id) {
                this.getLPSymbolMapAction();
            },
        },
        updateStatus(nv) {
            if (nv) {
                this.dialogRemove.visible = false;
                this.dialogMapping.visible = false;
                this.UPDATE_STATUS(false);
                this.getLPSymbolMapAction();
            }
        },
    },
    methods: {
        ...mapActions("LP", [
            "getLPSymbolMapAction",
            "updateLPSymbolMapAction",
            "deleteLPSymbolMapAction",
        ]),
        ...mapMutations("LP", ["UPDATE_STATUS"]),
        /**
         * On lp selection change
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        changeLp(data) {
            this.selectedLpId = data.id;
            this.selectedLp = data.lp_name;
        },
        /**
         * Refresh Position Rec Data
         * @return  {[type]}  [return description]
         */
        refreshMapping() {
            this.getLPSymbolMapAction();
        },
        confirmDeletion() {
            const id = this.removeData[0].id;
            this.deleteLPSymbolMapAction({ id: id });
        },
        toggleDialog(data) {
            this.dialogMapping.visible = true;
            const editingData = {
                id: data.id,
                api_code: data.api_code,
                symbol: data.symbol,
                pk: data.pk,
                ratio: data.ratio,
            };
            this.dialogMapping.title = "Update Symbol Mapping";
            this.dialogMapping.symbolMapInfo = editingData;
        },
        updateMapping() {
            this.updateLPSymbolMapAction(this.dialogMapping.symbolMapInfo);
        },
        onSearch(field) {
            setTimeout(() => {
                this.$refs[field].focus();
            }, 200);
        },
    },
};
</script>
