<template>
    <div class="home d-flex" v-resize="onResize">
        <v-card class="d-flex flex-grow-1">
            <v-container fluid class="d-flex flex-grow-1 flex-column">
                <v-row class="d-flex flex-grow-1">
                    <v-col cols="12">
                        <div style="display: flex">
                            <v-tabs v-model="selectedModule">
                                <v-tab key="lpAccount">LP Account</v-tab>
                                <v-tab key="positionRec">Position Rec</v-tab>
                                <v-tab key="mapping">Mapping</v-tab>
                            </v-tabs>
                            <!-- <v-btn
                                style="float: right"
                                color="primary"
                                small
                                class="mt-2"
                                @click="toLPConfig"
                                v-show="showButton"
                                >To LP Config</v-btn
                            >-->
                        </div>
                    </v-col>
                </v-row>

                <LPInfo
                    v-if="selectedModule === 0"
                    :tableHeight="lpInfoTableHeight"
                    @addSymbol="handleAddSymbol"
                />
                <PositionRec
                    v-if="selectedModule === 1"
                    :tableHeight="positionRecTableHeight"
                />
                <SymbolMap
                    v-if="selectedModule === 2"
                    :tableHeight="mapTableHeight"
                    :batch-maps="waitToAddSymbols"
                />
            </v-container>
        </v-card>
    </div>
</template>
<script>
import LPInfo from "./component/ComponentLPInfo";
import PositionRec from "./component/ComponentPositionRec";
import SymbolMap from "./component/ComponentSymbolMapping";
import { mapActions } from "vuex";

export default {
    components: { LPInfo, PositionRec, SymbolMap },
    data() {
        return {
            selectedModule: 0,
            mapTableHeight: 500,
            positionRecTableHeight: 500,
            lpInfoTableHeight: 500,
            waitToAddSymbols: [],
            showButton: false,
        };
    },
    created() {
        this.setHeights();
    },
    methods: {
        ...mapActions("LP", ["getLPInfoAction"]),
        setHeights() {
            const innerHeight = window.innerHeight;
            this.lpInfoTableHeight =
                innerHeight - 243 > 500 ? innerHeight - 243 : 500;
            this.mapTableHeight =
                innerHeight - 283 > 500 ? innerHeight - 283 : 500;
            this.positionRecTableHeight =
                innerHeight - 283 > 500 ? innerHeight - 283 : 500;
        },
        onResize() {
            this.setHeights();
        },
        /**
         * Change tab view
         * @param   {[type]}  key  [key description]
         * @return  {[type]}       [return description]
         */
        changeView(key) {
            this.selectedModule = key;
        },
        handleAddSymbol(symbols) {
            this.waitToAddSymbols = symbols;
            this.selectedModule = 2;
        },
        toLPConfig() {
            let routeData = this.$router.resolve({
                name: "Settings",
                query: { editing_target: "lp" },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        // handle batch add map
        if (this.$route.query.symbol_map) {
            this.selectedModule = 2;
            this.waitToAddSymbols = decodeURIComponent(
                this.$route.query.symbol_map
            ).split(",");
            this.$router.push({ query: {} }); // clean route params
        }
        let params = { active: 1 };
        this.getLPInfoAction(params);
        const permission = JSON.parse(
            localStorage.getItem("permission")
        ).frontPermission;

        if (permission?.settings?.child.includes("lpConfig")) {
            this.showButton = true;
        } else {
            this.showButton = false;
        }
    },
};
</script>
