<template>
    <v-row class="dialog-wrap" justify="center">
        <v-dialog v-model="dialogShow" persistent max-width="800px">
            <v-card :loading="loading">
                <v-card-title>
                    <span class="text-h5">Add Symbol Map</span>
                </v-card-title>
                <v-tabs v-model="activeTab">
                    <v-tab v-for="item in baseBatchMaps" :key="item.tabTitle">
                        {{ item.tabTitle }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeTab">
                    <v-tab-item
                        v-for="item in baseBatchMaps"
                        :key="item.tabTitle"
                        eager
                    >
                        <v-form ref="form" v-model="valid">
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="item.api_code"
                                                label="LP (API) Symbol Name"
                                                :rules="[rules.required]"
                                            />
                                        </v-col>
                                        <!-- <v-col cols="12">
                                            <v-text-field
                                                v-model="item.symbol"
                                                label="Symbol*"
                                                :rules="[rules.required]"
                                            />
                                        </v-col> -->
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="item.pk"
                                                label="PK Symbol Name"
                                                :rules="[rules.required]"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="item.ratio"
                                                type="number"
                                                label="Multiplier (The multiplier between LP and PK, i.e. LP volume = PK volume * Multiplier)"
                                                :hide-spin-buttons="true"
                                                :rules="[rules.required]"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogShow = false"
                        :loading="loading"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="handleSave"
                        :loading="loading"
                    >
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { BatchAddSymbolMap } from "@services/lp/symbol-map";
import { snackbar } from "@components/mixins/snackbar";
import deepClone from "deep-clone";
import { mapState } from "vuex";

export default {
    mixins: [snackbar],
    props: {
        selectedLpId: Number,
        batchMaps: Array // wait to add maps
    },
    data() {
        return {
            dialogShow: false,
            loading: false,
            baseBatchMaps: [],
            activeTab: 0,
            valid: true,
            rules: {
                required: value => !!value || "Param is required.",
                number: value =>
                    !/^\d+$/.test(value) || "Param must be a number."
            }
        };
    },
    computed: {
        ...mapState("LP", ["lpList"])
    },
    watch: {
        batchMaps: {
            immediate: true,
            once: true, // Prevent multiple triggers
            handler(nv) {
                if (nv.length) {
                    this.baseBatchMaps = nv.map(item => {
                        // const symbol = item.split("_")[1].replace(/\//, "");
                        const symbol = item.split("_")[1];
                        return {
                            tabTitle: item,
                            lp_id: this.lpList.find(
                                lpData => lpData.lp_name == item.split("_")[0]
                            )?.id,
                            api_code: item.split("_")[1],
                            symbol,
                            pk: item.split("_")[1],
                            ratio: 1
                        };
                    });
                    this.dialogShow = true;
                }
            }
        }
    },
    methods: {
        async handleSave() {
            this.loading = true;
            const forms = this.$refs.form;
            let allDone = false;
            for (let i = 0, len = forms.length; i < len; i++) {
                if (!forms[i].validate()) {
                    this.activeTab = i;
                    break;
                }
                if (i === len - 1) {
                    allDone = true;
                }
            }
            if (allDone) {
                const params = {
                    symbol_map: deepClone(this.baseBatchMaps)
                };
                params.symbol_map.map(item => delete item.tabTitle);

                try {
                    const {
                        data: { msg }
                    } = await BatchAddSymbolMap(params);
                    this.successMsg = msg;
                    this.loading = false;
                    this.snackBarSuccess(msg || 'Successfully');
                    this.dialogShow = false;

                } catch (e) {
                    this.snackBarDanger(
                        e.response?.data?.msg || "Can't Add Maps"
                    );
                    this.loading = false;
                }
            }
        }
    }
};
</script>
