var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home d-flex"},[_c('v-container',{staticClass:"d-flex flex-grow-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-grow-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"lg":"7","md":"7","xs":"12","cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 pb-2",staticStyle:{"border-bottom":"1px solid #404040"}},[_c('div',{staticClass:"text-left v-card-custom-title-left"},[_vm._v(" Current Position ")]),_c('div',{staticClass:"text-right v-card-custom-title-right"},[_c('v-menu',{attrs:{"offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 ml-2",attrs:{"small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedLp)+" ")])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.lpList),function(item,index){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.changeLp(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.lp_name.toUpperCase()))])],1)}),1)],1),_c('div',{staticStyle:{"display":"inline-table"}},[_c('v-btn',{attrs:{"small":"","color":"default","loading":_vm.positionLoading},on:{"click":_vm.refreshPosition}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-menu',{attrs:{"offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"default","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.positionTimer == 0 ? "off" : _vm.positionTimer + "s")+" ")])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.timerOption),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){return _vm.changeTimer(
                                                    item.value,
                                                    'position'
                                                )}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.positionTimer != 0),expression:"positionTimer != 0"}],staticStyle:{"position":"relative","height":"3px","bottom":"3px"},attrs:{"value":_vm.positionTimerValue}})],1),(_vm.selectedSymbols.length)?_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"small":"","color":"default"},on:{"click":_vm.handleAddSymbol}},[_vm._v("Add Symbol")]):_vm._e()],1)]),_c('v-data-table',{staticClass:"flex-grow-1",attrs:{"dense":"","headers":_vm.positionHeader,"items":_vm.lpPositionData,"item-key":"id","loading":_vm.positionLoading,"loading-text":"Loading... Please wait","items-per-page":-1,"height":_vm.tableHeight,"hide-default-footer":true,"fixed-header":"","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"header.Symbol",fn:function(ref){
                                                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.onSearch('symbolSearch')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('div',{staticStyle:{"background-color":"#1e1e1e","width":"280px"}},[_c('v-text-field',{ref:"symbolSearch",staticClass:"px-4",staticStyle:{"width":"100%"},attrs:{"type":"text","label":"Enter the search term","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2 mb-2",staticStyle:{"padding":"6px"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.search = ''}}},[_vm._v(" Clean ")])],1)])]}},{key:"item",fn:function(ref){
                                                var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"fixCol_first",attrs:{"column":"lp"}},[_vm._v(" "+_vm._s(item.LP.toUpperCase())+" ")]),_c('td',{attrs:{"column":"Symbol"}},[(item.isSelectable != true)?_c('span',[_vm._v(_vm._s(item.Symbol))]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"color":"red"},on:{"click":function($event){_vm.selectedSymbols.push(
                                                        item
                                                    );
                                                    _vm.handleAddSymbol();}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.Symbol)+" ")])]}}],null,true)},[_c('span',[_vm._v("Action required to map this symbol ")])])],1),_c('td',{attrs:{"column":"Margin_Rate","align":"right"}},[_vm._v(" "+_vm._s(item["Margin Rate"] === "N/A" ? item["Margin Rate"] : item["Margin Rate"] + "%")+" ")]),_c('td',{attrs:{"column":"Position","align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( parseFloat( item.Position ).toFixed(2) ))+" ")]),_c('td',{attrs:{"column":"Total_VWAP","align":"right"}},[_vm._v(" "+_vm._s(item["Total VWAP"])+" ")]),_c('td',{attrs:{"column":"Margin","align":"right"}},[_vm._v(" "+_vm._s(item.Margin === "N/A" ? item.Margin : _vm.numberWithCommas( parseFloat( item.Margin ).toFixed(2) ))+" ")]),_c('td',{attrs:{"column":"Unrealized_PNL_CCY_Account","align":"right"}},[_vm._v(" "+_vm._s(item["Unrealized P&L"] === "N/A" ? item["Unrealized P&L"] : _vm.numberWithCommas( parseFloat( item["Unrealized P&L"] ).toFixed(2) ))+" ")]),_c('td',{staticClass:"fixCol_last",attrs:{"column":"last_update"}},[_vm._v(" "+_vm._s(item.updated_at)+" ")])])]}}]),model:{value:(_vm.selectedSymbols),callback:function ($$v) {_vm.selectedSymbols=$$v},expression:"selectedSymbols"}})],1)],1),_c('v-col',{attrs:{"lg":"5","md":"5","xs":"12","cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 pb-2",staticStyle:{"border-bottom":"1px solid #404040"}},[_c('div',{staticClass:"text-left v-card-custom-title-left"},[_vm._v(" Account Info ")]),_c('div',{staticClass:"text-right v-card-custom-title-right"},[_c('div',{staticStyle:{"display":"inline-table"}},[_c('v-btn',{attrs:{"small":"","color":"default","loading":_vm.accountLoading},on:{"click":_vm.refreshAccount}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-menu',{attrs:{"offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"default","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.accountTimer == 0 ? "off" : _vm.accountTimer + "s")+" ")])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.timerOption),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){return _vm.changeTimer(
                                                    item.value,
                                                    'account'
                                                )}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.accountTimer != 0),expression:"accountTimer != 0"}],staticStyle:{"position":"relative","height":"3px","bottom":"3px"},attrs:{"value":_vm.accountTimerValue}})],1)])]),_c('v-data-table',{staticClass:"flex-grow-1",attrs:{"dense":"","headers":_vm.accountHeader,"items":_vm.filteredAccountData,"loading":_vm.accountLoading,"loading-text":"Loading... Please wait","items-per-page":-1,"height":_vm.tableHeight,"hide-default-footer":true,"fixed-header":"","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                                var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"fixCol_first",attrs:{"column":"lp"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [(_vm.isWorkingDay)?_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isOutsideOfTimeOffset(
                                                        item.updated_at
                                                    )
                                                ),expression:"\n                                                    isOutsideOfTimeOffset(\n                                                        item.updated_at\n                                                    )\n                                                "}],staticStyle:{"color":"red","margin-right":"5px"},attrs:{"size":"15"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("LP account info not up to date, last update at: "+_vm._s(item.updated_at))])]),_vm._v(" "+_vm._s(item.LP.toUpperCase())+" ")],1),_c('td',{attrs:{"column":"Margin%","align":"right"}},[_c('div',{style:('color:' +
                                            (item['Margin Utilization %'] >
                                            50
                                                ? 'red'
                                                : 'white'))},[_vm._v(" "+_vm._s(item[ "Margin Utilization %" ].toFixed(2) + "%")+" ")])]),_c('td',{attrs:{"column":"Used","align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( parseFloat(item.Margin).toFixed( 2 ) ))+" ")]),_c('td',{attrs:{"column":"Free","align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( item["Free Margin"].toFixed(2) ))+" ")]),_c('td',{attrs:{"column":"Equity","align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( item.Equity.toFixed(2) ))+" ")]),_c('td',{attrs:{"column":"FPNL","align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( item["Unrealized P&L"].toFixed( 2 ) ))+" ")]),_c('td',{attrs:{"column":"Balance","align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( item.Balance.toFixed(2) ))+" ")]),_c('td',{attrs:{"column":"Credit","align":"right"}},[_vm._v(" "+_vm._s(item.Credit == null ? null : _vm.numberWithCommas( parseFloat( item.Credit ).toFixed(2) ))+" ")]),_c('td',{staticClass:"fixCol_last",attrs:{"column":"Last Update"}},[_vm._v(" "+_vm._s(item.updated_at)+" ")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }